import { navRoutes } from "page_routes"

export const navigationCardData = [
  {
    heading: "Study Abroad Counselling",
    subHeading: "Speak with an education counsellor",
    rederectIcon: `${process.env.CDN_URL}/assets/images/ielts/right_open_arrow.svg`,
    image: `${process.env.CDN_URL}/assets/images/graduation_hat.png`,
    redirectingLink: navRoutes.PROFILE,
  },
  {
    heading: "IELTS Exam Preparation",
    subHeading: "Get IELTS classes, mock tests & more",
    rederectIcon: `${process.env.CDN_URL}/assets/images/ielts/right_open_arrow.svg`,
    image: `${process.env.CDN_URL}/assets/images/ielts_medal.png`,
    redirectingLink: navRoutes.IELTS_STUDENT_DASHBOARD,
  },
]
