import React, { FunctionComponent, useEffect, useState } from "react"
import NavigationModal from "components/NavigationModal"
import useLocation from "hooks/login/useLocation"
import { checkIfMENAUser } from "utils/country_utils"
import { useSelector } from "react-redux"
import { State } from "Interfaces/storeSchema"

const NavigationModalWrapper: FunctionComponent = () => {
  const [isNaviagtionModalOpen, setIsNaviagtionModalOpen] =
    useState<boolean>(false)
  const auth = useSelector((state: State) => state.auth.auth)
  const { userCountry } = useLocation()
  const handleRedirectionModal = (isOpen: boolean) => {
    setIsNaviagtionModalOpen(isOpen)
  }

  useEffect(() => {
    if (auth?.isValid && !checkIfMENAUser(userCountry)) {
      setIsNaviagtionModalOpen(true)
    }
  }, [auth?.isValid])

  return (
    <div>
      {isNaviagtionModalOpen && (
        <NavigationModal
          isFormModalOpen={isNaviagtionModalOpen}
          handleModalOpen={handleRedirectionModal}
        />
      )}
    </div>
  )
}

export default NavigationModalWrapper
